/**
 * Turns any number of strings into a page title.
 *
 * Example:
 * pageTitle('Home') => 'Home | Rooquest'
 * pageTitle('Home', 'About') => 'Home | About | Rooquest'
 */
export default function pageTitle(...page: (string | undefined)[]) {
  const pageTitles = page.filter(Boolean)

  if (pageTitles.length === 0) {
    return 'Rooquest'
  }

  return `${pageTitles.join(' | ')} | Rooquest`
}
